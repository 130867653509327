import { React, styled } from 'x'
import { textStyles } from 'styles'
import { useState, useEffect } from 'hooks'
import ArticleOverviewBlock from '../blocks/articleOverviewBlock'
import Spinner from '../components/spinner'

import Cookies from 'js-cookie'

const Wrapper = styled.article`
  width: 100%;
  > img {
    width: 100%;
    height: auto;
  }
`

const Headline = styled.h1`
  ${textStyles.h1Style}
`

const ErrorMessage = styled.p``

const Page = (props) => {
  const {
    pageContext: { latestArticles },
    location: { origin, hostname },
  } = props

  const [error, setError] = useState('')

  useEffect(() => {
    const getPayment = async () => {
      const apiHost = hostname == 'localhost' ? 'http://localhost:3000' : origin
      const paymentId = Cookies.get('paymentId')

      if (!paymentId) {
        console.error('No payment id found in cookies', paymentId)
        return setError('Probeer het later nog eens')
      }

      try {
        const result = await fetch(
          `${apiHost}/api/stripe/get_payment?id=${paymentId}`
        )

        if (result.status !== 200) {
          throw "Stripe couldn't find the payment"
        }

        const { status } = await result.json()
        window.location.href =
          origin +
          (status === 'paid' ? '/donatie-geslaagd' : '/donatie-niet-gelukt')
        Cookies.remove('paymentId')
      } catch (err) {
        console.error('Error getting payment', err, paymentId)
        setError(`Something went wrong: ${err}`)
      }
    }

    getPayment()
  }, [])

  return (
    <Wrapper>
      <Headline>
        {error.length > 0 ? 'Er is iets fout gegaan' : 'Een moment geduld...'}
      </Headline>
      {error.length > 0 ? <ErrorMessage>{error}</ErrorMessage> : <Spinner />}
      {latestArticles && (
        <ArticleOverviewBlock
          articles={latestArticles}
          title={'Laatste berichten'}
        />
      )}
    </Wrapper>
  )
}

export default Page
