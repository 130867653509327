import { React, styled, mq } from 'x'
import { default as getBlock } from '../blocks/getBlock'

const StyledSidebar = styled.aside`
  width: 30%;
  padding: 20px;

  ${mq.below('lg')} {
    order: 3;
    width: 100%;
    padding: 0;
  }
`

const Sidebar = (props) => {
  const { sidebar, podcastThumbnail } = props

  return (
    <StyledSidebar>
      {sidebar &&
        sidebar.map((node, index) => getBlock(node, index, podcastThumbnail))}
    </StyledSidebar>
  )
}

export default Sidebar
