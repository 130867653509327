import { React, styled } from 'x'
import { Link } from 'common'
import { colors } from 'styles'

const Wrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid ${colors.borderColor};
  border-bottom: 1px solid ${colors.borderColor};
`

const Label = styled.span`
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  color: ${colors.lightGrey};
`

const StyledLink = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  padding: 5px 10px;
  min-width: 30px;
  margin: 0 10px;
  text-decoration: none;
  background-color: ${(props) =>
    props.current ? colors.secundaryBlue : 'transparent'};
  color: ${(props) => (props.current ? 'white' : '#666')};
  border: ${(props) => (props.current ? 'none' : `1px solid #e3e3e3`)};
  &:first-of-type {
    margin-left: 0;
  }
`

const Info = styled.span`
  margin-left: auto;
  font-size: 12px;
  color: #666;
`

const Seperator = styled.div`
  margin: 0 10px;
`

const Pagination = (props) => {
  const { origin, category, totalPages, currentPage } = props

  const previousPage = currentPage - 1
  const nextPage = currentPage + 1

  let pagination = []
  pagination.push(
    <StyledLink
      to={`/articles/${category}/page/1/`}
      key={'page-1'}
      current={currentPage === 1 ? 1 : 0}
    >
      1
    </StyledLink>
  )

  const neighbours = 2
  if (currentPage - neighbours > 2) {
    pagination.push(<div key={'seperator'}>...</div>)
  }

  for (let i = currentPage - neighbours; i <= currentPage + neighbours; i++) {
    if (i <= 1 || i >= totalPages) {
      continue
    }
    let active = currentPage === i
    let path = `/articles/${category}/page/${i}`
    pagination.push(
      <StyledLink to={path} key={`page-${i}`} current={active ? 1 : 0}>
        {i}
      </StyledLink>
    )
  }

  if (currentPage + neighbours < totalPages - 1) {
    pagination.push(<Seperator key={'seperator-2'}>...</Seperator>)
  }

  pagination.push(
    <StyledLink
      to={`/articles/${category}/page/${totalPages}`}
      key={'total-pages'}
      current={currentPage === totalPages ? 1 : 0}
    >
      {totalPages}
    </StyledLink>
  )

  return (
    <Wrapper>
      {currentPage && previousPage > 0 && (
        <StyledLink to={`/articles/${category}/page/${currentPage - 1}`}>
          Vorige pagina
        </StyledLink>
      )}
      {pagination}
      {nextPage && nextPage <= totalPages && (
        <StyledLink to={`/articles/${category}/page/${currentPage + 1}`}>
          Volgende pagina
        </StyledLink>
      )}
      <Info>{`Page ${currentPage} of ${totalPages}`}</Info>
    </Wrapper>
  )
}

export default Pagination
