import { React, Link, styled, css, mq } from 'x'
import { colors } from 'styles'

import FacebookIcon from '../assets/svg/facebook_brands_icon.svg'
import InstagramIcon from '../assets/svg/instagram_brands_icon.svg'
import YoutubeIcon from '../assets/svg/youtube_brands_icon.svg'
import TwitterIcon from '../assets/svg/twitter_brands_icon.svg'
import SpotifyIcon from '../assets/svg/spotify_brands_icon.svg'

const FooterWrapper = styled.footer`
  width: calc(100% + 40px);
  margin: 0 -20px;
  display: flex;
  flex-direction: column;
  order: 4;
`

const SocialBar = styled.div`
  width: 100%;
  padding: 30px 0;
  background-color: #222222;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const SocialBtn = styled.button`
  display: inline-flex;
  border: 0;
  background: transparent;
`

const SocialIconLink = styled(Link)`
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg path {
    transition: fill 0.3s ease;
  }
  &:hover {
    svg path {
      fill: ${(props) => (props.hover ? props.hover : colors.primaryBlue)};
    }
  }
`

const SocialLink = styled(Link)`
  display: flex;
  margin: 0 10px;
  align-items: center;
  color: #fff;
  font-family: 'Open Sans', Arial, sans-serif;
  font-size: 13px;
  line-height: 40px;
  text-decoration: none;
  text-transform: uppercase;
  ${mq.below('lg')} {
    display: none;
  }
`

const FooterNavBar = styled.div`
  width: 100%;
  padding: 8px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0a0a0a;
  color: #ccc;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  ${mq.below('lg')} {
    flex-direction: column;
  }
`

const Copyright = styled.small`
  font-size: 11px;
  ${mq.below('lg')} {
    order: 2;
    margin: 7px 0;
    text-align: center;
  }
`

const FooterNavigation = styled.nav`
  ${mq.below('lg')} {
    order: 1;
    margin: 7px 0;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
  }
`

const linkStyle = css`
  color: #ccc;
  text-decoration: none;
  margin: 0 15px;
`

const StyledLink = styled(Link)`
  ${linkStyle}
`

const StyledA = styled.a`
  ${linkStyle};
  cursor: pointer;
`

const Footer = (props) => {
  const { menu, socials } = props

  const date = new Date()
  const year = date.getFullYear()

  return (
    <FooterWrapper>
      <SocialBar>
        {socials && socials.facebookUrl && (
          <SocialBtn>
            <SocialIconLink
              to={socials.facebookUrl}
              target="_blank"
              hover="#4267B2"
            >
              <FacebookIcon />
            </SocialIconLink>
            <SocialLink to={socials.facebookUrl} target="_blank">
              Facebook
            </SocialLink>
          </SocialBtn>
        )}
        {socials && socials.instagramUrl && (
          <SocialBtn>
            <SocialIconLink
              to={socials.instagramUrl}
              target="_blank"
              hover="#F56040"
            >
              <InstagramIcon />
            </SocialIconLink>
            <SocialLink to={socials.instagramUrl} target="_blank">
              Instagram
            </SocialLink>
          </SocialBtn>
        )}
        {socials && socials.spotifyUrl && (
          <SocialBtn>
            <SocialIconLink
              to={socials.spotifyUrl}
              target="_blank"
              hover="#1DB954"
            >
              <SpotifyIcon />
            </SocialIconLink>
            <SocialLink to={socials.spotifyUrl} target="_blank">
              Spotify
            </SocialLink>
          </SocialBtn>
        )}
        {socials && socials.twitterUrl && (
          <SocialBtn>
            <SocialIconLink
              to={socials.twitterUrl}
              target="_blank"
              hover="#1DA1F2"
            >
              <TwitterIcon />
            </SocialIconLink>
            <SocialLink to={socials.twitterUrl} target="_blank">
              Twitter
            </SocialLink>
          </SocialBtn>
        )}
        {socials && socials.youtubeUrl && (
          <SocialBtn>
            <SocialIconLink
              to={socials.youtubeUrl}
              target="_blank"
              hover="#FF0000"
            >
              <YoutubeIcon />
            </SocialIconLink>
            <SocialLink to={socials.youtubeUrl} target="_blank">
              Youtube
            </SocialLink>
          </SocialBtn>
        )}
      </SocialBar>
      <FooterNavBar>
        <Copyright>
          &copy; {`Jensen.nl ${year} - All rights reserved`}
        </Copyright>
        <FooterNavigation>
          {menu &&
            menu.map(({ title, slug }, index) => (
              <StyledLink key={index} to={`/${slug}`}>
                {title}
              </StyledLink>
            ))}
        </FooterNavigation>
      </FooterNavBar>
    </FooterWrapper>
  )
}

export default Footer
