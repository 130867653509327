import { breakpoints } from 'styles'
import { createMediaQueries } from 'library'

export const mq = createMediaQueries(breakpoints)

export { default as React } from 'react'

export { default as styled, css, keyframes } from 'styled-components'

export { rgba } from 'polished'

export { Link } from 'common'

export { navigate, withPrefix, StaticQuery } from 'gatsby'

export { default as Helmet } from 'react-helmet'

// export { default as Image } from 'gatsby-plugin-image' // No longer exported
