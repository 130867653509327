import { React, styled } from 'x'
import { textStyles, colors } from 'styles'
import { useState, useEffect } from 'hooks'
import { Constants } from '../common/common'
import ArticleNav from '../components/articleNav'
import SocialSharer from '../components/socialSharer'
import Layout from '../components/layout'
import FacebookComments from '../components/facebookComments'
import ArticleOverviewBlock from '../blocks/articleOverviewBlock'
import getBlock from '../blocks/getBlock'

const Wrapper = styled.article`
  width: 100%;
`

const Headline = styled.h1`
  ${textStyles.h1Style}
  margin: 0 0 15px;
  padding: 0;
  background-color: transparent;
  font-size: 34px;
  line-height: 44px;
  color: ${colors.textColor};
  text-align: left;
`

const Intro = styled(textStyles.paragraphStyle)`
  margin: 0 0 10px;
  display: inline-flex;
  ul {
    margin: 10px 0;
    padding: 0 0 0 20px;
    li {
      font-family: 'Oswald', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
        sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
      + li {
        margin-top: 5px;
      }
    }
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const DateWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  margin: 0 0 20px;
  padding: 0 0 10px;
  border-bottom: 2px solid #ebebeb;
  font-size: 14px;
`

const Article = (props) => {
  const {
    location: { href },
    pageContext: {
      title,
      intro,
      blocks,
      meta: { firstPublishedAt },
      prevArticle,
      nextArticle,
      latestArticles,
      slug,
    },
  } = props

  const [socialInBlock, setSocialInBlock] = useState(false)
  const isNodeSocialShareButtons = (node) =>
    node.model && node.model.name === Constants.SHARE_BUTTONS_BLOCK

  const parsedDate = new Date(firstPublishedAt)
  const year = new Intl.DateTimeFormat('nl', { year: 'numeric' }).format(
    parsedDate
  )
  const month = new Intl.DateTimeFormat('nl', { month: '2-digit' }).format(
    parsedDate
  )
  const day = new Intl.DateTimeFormat('nl', { day: '2-digit' }).format(
    parsedDate
  )

  useEffect(() => {
    blocks.forEach((node) => {
      if (isNodeSocialShareButtons(node)) {
        setSocialInBlock(true)
      }
    })
  }, [])

  return (
    <Layout {...props}>
      <Wrapper>
        <Headline>{title}</Headline>
        <Intro dangerouslySetInnerHTML={{ __html: intro }}></Intro>
        <DateWrapper>
          <time dateTime={firstPublishedAt}>{`${day}/${month}/${year}`}</time>
        </DateWrapper>
        {blocks &&
          blocks.map((node, index) => {
            if (isNodeSocialShareButtons(node)) {
              return <SocialSharer key={index} url={href} title={title} />
            }
            return getBlock(node, index)
          })}
        {!socialInBlock && <SocialSharer url={href} title={title} />}
        {prevArticle && nextArticle && (
          <ArticleNav prevArticle={prevArticle} nextArticle={nextArticle} />
        )}
        <FacebookComments url={href} />
        <ArticleOverviewBlock
          articles={latestArticles}
          title={'Laatste berichten'}
        />
      </Wrapper>
    </Layout>
  )
}

export default Article
