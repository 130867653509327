import { React, styled } from 'x'
import { useEffect, useState } from 'hooks'

const Comments = styled.div`
  width: 100%;
  margin: 20px 0;
`

const NoConsent = styled.p`
  margin: 40px 0;
  text-align: center;
`

const FacebookComments = ({ url }) => {
  const [sdkAvailable, setsdkAvailable] = useState(false)
  const [hasConsent, setHasConsent] = useState(true)
  const facebookAppId = '227755555198960'

  useEffect(() => {
    const intervalCheck = setInterval(() => {
      if (window.FB) {
        setsdkAvailable(true)
        clearInterval(intervalCheck)

        window.FB.XFBML.parse()
      }
    }, 1000)

    return () => {
      clearInterval(intervalCheck)
    }
  }, [])

  useEffect(() => {
    // const onConsent = () => {
    //   let consent = Cookiebot?.consent?.marketing
    //   setHasConsent(consent)
    //   if (window.FB || !consent) {
    //     return
    //   }

    const script = document.createElement('script')
    script.src = `https://connect.facebook.net/nl_NL/sdk.js#xfbml=1&version=v6.0&appId=${facebookAppId}&autoLogAppEvents=1`
    script.async = true
    script.defer = true
    script.crossOrigin = 'anonymous'
    document.getElementsByTagName('head')[0].appendChild(script)
    // }
    // window.addEventListener('CookiebotOnConsentReady', onConsent)

    // return () =>
    //   window.removeEventListener('CookiebotOnConsentReady', onConsent)
  }, [])

  const changeConsent = (e) => {
    e.preventDefault()
    if (typeof Cookiebot !== 'undefined') {
      Cookiebot?.show()
    }
  }

  return (
    <>
      {hasConsent === false && (
        <NoConsent>
          Je hebt geen toegang gegeven voor marketing cookies welke benodigd
          zijn om Facebook comments in te laden.{' '}
          <a href="" onClick={changeConsent}>
            Klik hier om je voorkeuren aan te passen.
          </a>
        </NoConsent>
      )}
      {sdkAvailable && (
        <Comments
          className="fb-comments"
          data-href={url}
          data-numposts="5"
          data-width="100%"
        />
      )}
    </>
  )
}

export default FacebookComments
