import { React, Link, Image, styled } from 'x'
import { Constants } from 'common'
import { colors, textStyles } from 'styles'
import { default as getBlock } from './getBlock'

const {
  VIDEO_BLOCK,
  VIDEO_EMBED_BLOCK,
  PLAYER_BLOCK,
  AUDIO_BLOCK,
  AUDIO_EMBED_BLOCK,
} = Constants

const Wrapper = styled.div`
  display: block;
  margin-bottom: 40px;
`

const Headline = styled.h1`
  ${textStyles.h1Style}
  background-color: ${(props) => (props.bg ? props.bg : colors.red)};
  padding: 6px 12px;
  color: #fff;
  margin-bottom: 10px;
`

const StyledLink = styled(Link)``

const ImageWrapper = styled.figure`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
`

const Thumbnail = styled.img`
  width: 100%;
  display: block;
`

const AudioWrapper = styled.div`
  > span audio {
    margin: 20px 0 0;
  }
`

const FeaturedArticleBlock = (props) => {
  const {
    title,
    showVideo,
    showAudio,
    titleBackground: { rgb },
    article: { slug, content, thumbnail },
  } = props

  let video
  let audio
  let fluid = thumbnail

  content.forEach((block, index) => {
    const modelName = block?.model?.name || ''
    const isVideoEnabledBlock =
      [VIDEO_BLOCK, VIDEO_EMBED_BLOCK, PLAYER_BLOCK].indexOf(modelName) !== -1
    const isAudioEnabledBlock =
      [AUDIO_BLOCK, AUDIO_EMBED_BLOCK].indexOf(modelName) !== -1

    if (showVideo && isVideoEnabledBlock) {
      block.thumbnail = fluid
      video = getBlock(block, index)
    } else {
      if (((block || {}).model || {}).name === VIDEO_BLOCK) {
        const {
          video: { previewImage },
        } = block

        fluid = previewImage
      }
    }

    if (showAudio && isAudioEnabledBlock) {
      audio = getBlock(block, index)
    }
  })

  return (
    <Wrapper>
      <Headline bg={rgb}>{title}</Headline>
      {showVideo && video ? (
        video
      ) : (
        <StyledLink to={slug}>
          <ImageWrapper>
            {fluid && <Thumbnail fadeIn={false} fluid={fluid.fluid} />}
          </ImageWrapper>
        </StyledLink>
      )}
      {showAudio && audio && <AudioWrapper>{audio}</AudioWrapper>}
    </Wrapper>
  )
}

export default FeaturedArticleBlock
