export default {
  AD_BLOCK: 'Google Ad Block',
  ARTICLE_OVERVIEW_BLOCK: 'Article Overview Block',
  AUDIO_BLOCK: 'Audio Block',
  AUDIO_EMBED_BLOCK: 'Audio Embed Block',
  DONATE_BLOCK: 'Donate Block',
  FEATURED_ARTICLE_BLOCK: 'Featured Article Block',
  LATEST_BLOCK: 'Latest Block',
  LATEST_PODCASTS_BLOCK: 'Latest Podcasts Block',
  IMAGE_BLOCK: 'Image Block',
  LINK_LIST_BLOCK: 'Link List Block',
  QUOTE_BLOCK: 'Quote Block',
  SEPERATOR_MENU_ITEM: 'Seperator Menu Item',
  SHARE_BUTTONS_BLOCK: 'Share Buttons Block',
  SOCIALS_BLOCK: 'Socials Block',
  SPONSOR_BLOCK: 'Sponsor Block',
  STRIPE_DONATE_BLOCK: 'Stripe Donate Block',
  TEXT_BLOCK: 'Text Block',
  VIDEO_BLOCK: 'Video Block',
  VIDEO_EMBED_BLOCK: 'Video Embed Block',
  PETJE_AF_BLOCK: 'Petje Af Block',
  PLAYER_BLOCK: 'Player Block',
}
