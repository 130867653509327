import { React, styled } from 'x'
import { Link } from 'common'
import { colors } from 'styles'

const Wrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Block = styled.div`
  display: inline-flex;
  flex-direction: column;
  ${({ alignment }) => alignment && `
    margin-left: auto;
  `}
`

const Label = styled.span`
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  color: ${ colors.lightGrey };
`

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 40px;
  color: ${ colors.textColor };
  font-size: 15px;
  line-height: 20px;
  text-decoration: none;
`

const ArticleNav = props => {
  const { prevArticle, nextArticle } = props

  return (
    <Wrapper>
        {prevArticle && prevArticle.slug && (
          <Block>
            <Label>Vorig artikel</Label>
            <StyledLink to={'/' + prevArticle.slug}>{ prevArticle.title }</StyledLink>
          </Block>
        )}
        {nextArticle && nextArticle.slug && (
          <Block alignment={ prevArticle && prevArticle.slug ? false : true} style={ { textAlign: 'right' } }>
            <Label>Volgend artikel</Label>
            <StyledLink to={'/' + nextArticle.slug}>{ nextArticle.title }</StyledLink>
          </Block>
        )}
    </Wrapper>
  )
}

export default ArticleNav
