import { React, Link, styled } from 'x'
import { colors } from 'styles'

import FacebookIcon from '../assets/svg/blue/facebook_brands_icon_blue.svg'
import InstagramIcon from '../assets/svg/blue/instagram_brands_icon_blue.svg'
import SpotifyIcon from '../assets/svg/blue/spotify_brands_icon_blue.svg'
import YoutubeIcon from '../assets/svg/blue/youtube_brands_icon_blue.svg'
import TwitterIcon from '../assets/svg/blue/twitter_brands_icon_blue.svg'
import RssIcon from '../assets/svg/blue/rss_icon_blue.svg'

const SocialBar = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${ colors.borderColor };
`

const SocialLink = styled(Link)`
  display: inline-flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  svg {
    height: 16px;
    width: auto;
    path {
      transition: .3s all ease;
    }
  }
  &:hover {
    svg path {
      fill: ${ props => props.hover ? props.hover : colors.primaryBlue };
    }
  }
`

const SocialBlock = props => {
  const { socials } = props

  return (
    <SocialBar>
      {socials && socials.facebookUrl && (
        <SocialLink to={ socials.facebookUrl } hover='#4267B2' target="_blank"><FacebookIcon /></SocialLink>
      )}
      {socials && socials.instagramUrl && (
        <SocialLink to={ socials.instagramUrl } hover='#F56040' target="_blank"><InstagramIcon /></SocialLink>
      )}
      {socials && socials.spotifyUrl && (
        <SocialLink to={ socials.spotifyUrl } hover="#1DB954" target="_blank"><SpotifyIcon /></SocialLink>
      )}
      {socials && socials.rssUrl && (
        <SocialLink to={ socials.rssUrl } hover={ colors.primaryBlue } target="_blank"><RssIcon /></SocialLink>
      )}
      {socials && socials.twitterUrl && (
        <SocialLink to={ socials.twitterUrl } hover="#1DA1F2" target="_blank"><TwitterIcon /></SocialLink>
      )}
      {socials && socials.youtubeUrl && (
        <SocialLink to={ socials.youtubeUrl } hover="#FF0000" target="_blank"><YoutubeIcon /></SocialLink>
      )}
    </SocialBar>
  )
}

export default SocialBlock
