import { React, styled, mq } from 'x'
import RatioImage from './ratioImage'
import { Link, Constants } from 'common'
import { colors, textStyles } from 'styles'
import { innoImg } from '../library'
import Img from 'gatsby-image'
const { VIDEO_BLOCK, VIDEO_EMBED_BLOCK } = Constants

const Wrapper = styled.div`
  flex: 0 ${(props) => (props.small ? '31%' : '48%')};
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  ${mq.below('lg')} {
    flex: 0 ${(props) => (props.small ? '31%' : '100%')};
  }
  ${mq.below('sm')} {
    flex: 0 100%;
  }
`

const ImageWrapper = styled.figure`
  position: relative;
  width: 100%;
  margin: 0;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.textColor};
  transition: color 0.3s ease;
  &:hover {
    color: ${colors.primaryBlue};
  }
`

const Title = styled.h3`
  ${textStyles.h3Style};
  font-size: 20px;
  text-align: left;
  background: transparent;
  color: rgb(17, 17, 17);
  padding: 0;
  margin: 14px 0;
  line-height: 24px;
`

const Description = styled(textStyles.paragraphStyle)`
  color: #777;
  font-size: 15px;
  line-height: 30px;
  p {
    margin: 0;
  }
`

const Article = (props) => {
  const {
    origin,
    small,
    article: { slug, title, description, thumbnail, content },
  } = props

  const path = origin ? `${origin}/${slug}` : '/' + slug
  const isVideoBlock = (b) => ((b || {}).model || {}).name === VIDEO_BLOCK
  const isVideoEmbedBlock = (b) =>
    ((b || {}).model || {}).name === VIDEO_EMBED_BLOCK

  let isVideo = false
  let fluid = thumbnail

  content.forEach((block) => {
    if (isVideoBlock(block) || isVideoEmbedBlock(block)) {
      isVideo = true
    }

    if (isVideoBlock(block) && block?.video?.previewImage) {
      const {
        video: { previewImage },
      } = block

      fluid = previewImage
    }
  })

  return (
    <Wrapper small={small}>
      <ImageWrapper>
        <StyledLink to={path}>
          <RatioImage ratioWidth={16} ratioHeight={9}>
            {fluid?.fluid && (
              <Img fadeIn={false} fluid={innoImg(fluid.fluid)} />
            )}
          </RatioImage>
        </StyledLink>
      </ImageWrapper>
      <StyledLink to={path}>
        <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
      </StyledLink>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
    </Wrapper>
  )
}

export default Article
