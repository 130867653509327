export { default as createMediaQueries } from './createMediaQueries'
import { useEffect } from 'hooks'

export const innoImg = (data) => {
  const replace = (source) => {
    if (/www.datocms-assets.com/.test(source)) {
      return source.replace(/www.datocms-assets.com/g, 'img.jensen.nl').replace(/&fm=jpg/g, '')
    }
    return source
  }

  if (typeof data === 'object' && data.src && data.srcSet) {
    return {
      ...data,
      src: replace(data.src),
      srcSet: replace(data.srcSet),
    }
  } else if (typeof data === 'string') {
    return replace(data)
  }
}

export const dateFormatter = new Intl.DateTimeFormat('nl', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
})

export const formattedDate = (date) => {
  const parsedDate = new Date(date)
  const year = new Intl.DateTimeFormat('nl', { year: 'numeric' }).format(
    parsedDate
  )
  const month = new Intl.DateTimeFormat('nl', { month: '2-digit' }).format(
    parsedDate
  )
  const day = new Intl.DateTimeFormat('nl', { day: '2-digit' }).format(
    parsedDate
  )

  return `${day}/${month}/${year}`
}

export const getAudioUrl = (audioId) => {
  let nodeCount = 3
  let node = Math.floor(Math.random() * nodeCount) + 1
  let name = `${audioId}_audio`
  let url = `https://node-${node}.cdn.jensen.nl/audio/${name}/${name}.mp3`
  let upload = {
    format: url.split('.').reverse()[0],
    url,
  }

  return upload
}
