import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  html {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    ${({ navOpen }) => navOpen && `
      overflow: hidden;
    `}
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #0a0a0a;
    color: #fff;
    ${({ navOpen }) => navOpen && `
      overflow: hidden;
    `}
  }

  * {
    box-sizing: border-box;
  }
`
