import { React, styled } from 'x'
import { useEffect } from 'hooks'

const AdWrapper = styled.div`
  width: 100%;
  display: block;
  margin: 0 auto;
`

const AdBlock = props => {
  useEffect(() => {
    if (window && window.adsbygoogle) {
      (adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, [])

  return (
    <>
      <AdWrapper dangerouslySetInnerHTML={ { __html: props.html } }></AdWrapper>
    </>
  )
}

export default AdBlock
