import { React, styled } from 'x'
import Layout from '../components/layout'
import { ArticleOverviewBlock } from 'blocks'

const Wrapper = styled.div`
  width: 100%;
`

const ArticleOverviewPage = (props) => {
  const {
    location: { origin },
    pageContext: { articles, title, currentPage, totalPages, categorySlug },
  } = props

  return (
    <Layout {...props}>
      <Wrapper>
        <ArticleOverviewBlock
          title={title}
          origin={origin}
          articles={articles}
          page={currentPage}
          totalPages={totalPages}
          categorySlug={categorySlug}
        />
      </Wrapper>
    </Layout>
  )
}

export default ArticleOverviewPage
