import { React, styled } from 'x'
import { textStyles } from 'styles'
import { innoImg } from 'library'

const Text = textStyles.paragraphStyle

const TextBlock = (props) => {
  const text = innoImg(props.text)
  return <Text dangerouslySetInnerHTML={{ __html: text }} />
}

export default TextBlock
