import { React, Link, styled, mq } from 'x'
import { default as Article } from '../components/article'
import Pagination from '../components/pagination'
import { colors, textStyles } from 'styles'

const Wrapper = styled.div`
  margin: 0 0 30px 0;
  border-bottom: ${(props) =>
    props.isBlock ? `1px solid ${colors.borderColor}` : 0};
`

const Headline = styled.h2`
  ${textStyles.h1Style}
  margin-top: 0;
  margin-bottom: 10px;
  flex-basis: 1;
  ${(props) => (props.color ? `background-color: ${props.color}` : '')};
`

const ArticleWrapper = styled.div`
  position: relative;
  top: -10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${mq.below('sm')} {
    > div:nth-child(n + 4) {
      display: ${(props) => (props.limitMobile ? 'none' : 'flex')};
    }
  }
`

const OverviewFooter = styled.div`
  flex-basis: 100%;
  display: inline-flex;
`

const StyledLink = styled(Link)`
  padding: 8px 10px;
  margin: 10px auto 20px;
  font-size: 14px;
  color: #777;
  border: 1px solid #777;
  text-decoration: none;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${colors.primaryBlue};
    border-color: ${colors.primaryBlue};
    color: ${colors.white};
  }
`

const ArticleOverviewBlock = (props) => {
  const {
    origin,
    articles,
    title,
    category,
    categorySlug,
    page,
    totalPages,
    amountOfArticles,
    readMoreButton,
    small,
    limitMobile,
    titleBackground,
  } = props

  const isBlock = page ? 0 : 1
  const catSlug = categorySlug ?? category?.slug ?? 'all'
  const currentIsLastpage = (amountOfArticles || 10) > articles.length

  let footer
  if (readMoreButton && isBlock) {
    footer = (
      <StyledLink to={`/articles/${catSlug}/page/${currentIsLastpage ? 1 : 2}`}>
        Bekijk meer
      </StyledLink>
    )
  } else if (page) {
    footer = (
      <Pagination
        origin={origin}
        category={catSlug}
        totalPages={totalPages}
        currentPage={page}
      />
    )
  }

  return (
    <Wrapper isBlock={isBlock} small={small}>
      <Headline color={titleBackground?.rgb}>{title}</Headline>
      <ArticleWrapper limitMobile={limitMobile}>
        {articles.map((item, index) => {
          const article = item.article ? item.article : item
          return (
            <Article
              key={index}
              article={article}
              origin={origin}
              small={small}
            />
          )
        })}
      </ArticleWrapper>
      <OverviewFooter>{footer}</OverviewFooter>
    </Wrapper>
  )
}

export default ArticleOverviewBlock
