import { React, styled } from 'x'
import { AudioBlock } from 'blocks'
import { getAudioUrl } from 'library'

const DefaultWrapper = styled.div`
  width: 100%;
  display: block;
  margin: ${(props) => (props.inArticle ? '20px 0' : 0)};
  iframe {
    width: 100% !important;
    ${({ spotifyEmbed }) =>
      !spotifyEmbed &&
      `
      height: auto;
    `}
  }
`

const AudioEmbedBlock = (props) => {
  const { embed, selfHostedAudioId, inArticle } = props
  const spotifyEmbed = embed.indexOf('spotify') > -1 ? 1 : 0

  if (selfHostedAudioId) {
    const upload = getAudioUrl(selfHostedAudioId)
    return <AudioBlock anchor={null} upload={upload} />
  }

  // Legacy support
  if (embed.match(/^https?:.*\.(mp3|ogg|wav)$/)) {
    let anchor
    let upload
    if (embed.indexOf('anchor.fm') !== -1) {
      anchor = embed
    } else {
      upload = {
        url: embed,
        format: embed.split('.').reverse()[0],
      }
    }
    return <AudioBlock anchor={anchor} upload={upload} />
  }

  return (
    <DefaultWrapper
      dangerouslySetInnerHTML={{ __html: embed }}
      inArticle={inArticle ? 1 : 0}
      spotifyEmbed={spotifyEmbed}
    />
  )
}

export default AudioEmbedBlock
