import styled from 'styled-components'

const embedWrapper = styled.div`
  position: relative; 
  max-width: 100%; 
  height: 0; 
  padding-bottom: 56.25%; 
  overflow: hidden; 
  iframe, object, embed {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100% !important; 
    height: 100%; 
  }
`

export default embedWrapper
