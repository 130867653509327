import { React, Link, styled, mq } from 'x'
import { breakpoints, colors } from 'styles'
import { logo, ad as banner } from '../../static/img'

import HamburgerIcon from '../assets/svg/hamburger_icon.svg'
import SearchIcon from '../assets/svg/search_icon.svg'
import CloseIcon from '../assets/svg/close_icon.svg'
import FacebookIcon from '../assets/svg/facebook_brands_icon.svg'
import InstagramIcon from '../assets/svg/instagram_brands_icon.svg'
import YoutubeIcon from '../assets/svg/youtube_brands_icon.svg'
import TwitterIcon from '../assets/svg/twitter_brands_icon.svg'
import SpotifyIcon from '../assets/svg/spotify_brands_icon.svg'

const Wrapper = styled.header`
  position: relative;
  min-height: 100px;
  width: ${breakpoints.xl}px;
  padding: 20px 20px 0 20px;
  margin: 0 auto;
  &:before {
    content: '';
    position: absolute;
    display: block;
    z-index: -1;
    width: calc(100% + 40px);
    height: auto;
    top: 0;
    left: -20px;
    right: -20px;
    bottom: 0;
    background: radial-gradient(
      circle at center,
      ${colors.gradientBlue} 40%,
      #0a0a0a 100%
    );
    ${mq.below('lg')} {
      display: none;
    }
  }

  ${mq.below('xl')} {
    width: ${breakpoints.lg}px;
  }

  ${mq.below('lg')} {
    position: static;
    width: 100%;
    background: #222222;
  }
`
const BrandingBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  ${mq.below('lg')} {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
`

const LogoFigure = styled.figure`
  width: 270px;
  height: auto;
  padding: 0;
  margin: 0;
  ${mq.below('lg')} {
    max-width: calc(100% - 130px);
    order: 3;
  }
`

const Logo = styled.img`
  width: 100%;
`

const DonateLink = styled.a`
  display: block;
`

const Advertisement = styled.img`
  width: 620px;
  height: auto;
  ${({ shadow }) =>
    shadow &&
    `
    box-shadow: 0 0 10px ${shadow};
  `}

  ${mq.below('xl')} {
    margin-left: 20px;
    width: 600px;
  }

  ${mq.below('lg')} {
    width: 100%;
    flex-basis: 100%;
    order: 1;
    margin: 0 0 20px 0;
  }
`

const Navigation = styled.nav`
  display: flex;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin: 20px 0 0px;
  padding: 0 20px;
  ${mq.below('lg')} {
    position: fixed;
    top: 0;
    left: -100%;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(69, 132, 112, 0.9) 0%,
      rgba(18, 129, 153, 0.9) 100%
    );
    padding: 10px;
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.5s ease;
    z-index: 50;
    ${({ open }) =>
      open &&
      `
      left: 0;
    `}
  }
`

const StyledLink = styled(Link)`
  position: relative;
  margin: 0 10px;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 48px;
  font-family: 'Oswald', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  color: rgba(244, 244, 244, 0.89);
  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 3px;
    background-color: ${colors.secundaryBlue};
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: width 200ms ease;
    translate: transformX(-50%);
    -webkit-transform: translateX(-50%);
    ${mq.below('lg')} {
      display: none;
    }
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
  ${mq.below('lg')} {
    line-height: 28px;
  }
`

const BtnStyling = (props) => `
  position: relative;
  display: none;
  justify-content: center;
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: transparent;
  border: 0;
  ${mq.below('lg')} {
    display: inline-flex;
  }
`

const HamburgerNavBtn = styled.button`
  ${BtnStyling}
  cursor: pointer;
  ${mq.below('lg')} {
    order: 2;
  }
`

const iconSize = (props) => `
  width: 28px;
  height: auto;
`

const StyledHamburgerIcon = styled(HamburgerIcon)`
  ${iconSize}
`

const SearchBtn = styled.span`
  ${BtnStyling}
  opacity: 0;
  ${mq.below('lg')} {
    order: 4;
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  ${iconSize}
`

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 20px;
  width: 25px;
  height: 25px;
  display: none;
  ${mq.below('lg')} {
    display: inline-flex;
  }
`

const SocialBar = styled.div`
  position: relative;
  width: 100%;
  display: none;
  padding: 10px;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  ${mq.below('lg')} {
    display: flex;
  }
`

const SocialBtn = styled.button`
  display: inline-flex;
  padding: 0;
  background: transparent;
  border: 0;
`

const SocialIconLink = styled(Link)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = (props) => {
  const { menu, socials, navOpen, toggleNavOpen } = props
  const donateUrl =
    process.env.header_donate_url || 'https://petjeaf.com/jensen'

  const refreshHome = () => {
    if (!window) return
    window.location.href = '/'
  }

  return menu ? (
    <Wrapper gradientColor={colors.primaryBlue}>
      <BrandingBar>
        <LogoFigure onClick={refreshHome}>
          <Logo src={logo} />
        </LogoFigure>
        <DonateLink href={donateUrl} target="_blank">
          <Advertisement shadow="#070707" src={banner} />
        </DonateLink>
        <HamburgerNavBtn onClick={() => toggleNavOpen(true)}>
          <StyledHamburgerIcon />
        </HamburgerNavBtn>
        <SearchBtn>
          <StyledSearchIcon />
        </SearchBtn>
      </BrandingBar>
      <Navigation open={navOpen ? 1 : 0} onClick={() => toggleNavOpen(false)}>
        <SocialBar>
          {socials && socials.facebookUrl && (
            <SocialBtn>
              <SocialIconLink to={socials.facebookUrl} target="_blank">
                <FacebookIcon />
              </SocialIconLink>
            </SocialBtn>
          )}
          {socials && socials.instagramUrl && (
            <SocialBtn>
              <SocialIconLink to={socials.instagramUrl} target="_blank">
                <InstagramIcon />
              </SocialIconLink>
            </SocialBtn>
          )}
          {socials && socials.spotifyUrl && (
            <SocialBtn>
              <SocialIconLink to={socials.spotifyUrl} target="_blank">
                <SpotifyIcon />
              </SocialIconLink>
            </SocialBtn>
          )}
          {socials && socials.twitterUrl && (
            <SocialBtn>
              <SocialIconLink to={socials.twitterUrl} target="_blank">
                <TwitterIcon />
              </SocialIconLink>
            </SocialBtn>
          )}
          {socials && socials.youtubeUrl && (
            <SocialBtn>
              <SocialIconLink to={socials.youtubeUrl} target="_blank">
                <YoutubeIcon />
              </SocialIconLink>
            </SocialBtn>
          )}
          <StyledCloseIcon open={navOpen ? 1 : 0} />
        </SocialBar>
        {menu?.map(({ url, label, page, newWindow }, index) => {
          const { slug, title } = page || {}
          const link = url ? url : `/${slug}`
          const name = label ? label : title

          return (
            <StyledLink
              key={index}
              to={`${link}`}
              target={newWindow ? '_blank' : '_self'}
            >
              {name}
            </StyledLink>
          )
        })}
      </Navigation>
    </Wrapper>
  ) : (
    <Wrapper>Header</Wrapper>
  )
}

export default Header
