import { React, styled } from 'x'
import { useEffect, useState, useRef } from 'hooks'

const AudioWrapper = styled.span`
  display: flex;
  width: 100%;
`

const AudioPlayer = styled.audio`
  width: inherit;
  margin: 20px 0;
`

const AudioBlock = (props) => {
  const { upload, anchor } = props
  const [audio, setAudio] = useState()
  const player = useRef()

  useEffect(() => {
    if (audio && player.current != null) {
      player.current.pause()
      player.current.currentTime = 0
      player.current.load()
    }

    setAudio({
      url: anchor ? anchor : upload.url,
      format: anchor ? 'mp3' : upload.format,
    })
  }, [upload])

  const mapMediaType = {
    mp3: 'audio/mpeg',
    ogg: 'audio/ogg',
    wav: 'audio/wav',
  }

  const audioUrl = audio?.url?.replace(/node-[0-9]/, 'node-4')

  return (
    <AudioWrapper>
      {audio && (
        <AudioPlayer ref={player} controls preload="none">
          <source src={audioUrl} type={mapMediaType[audio.format]}></source>
          Your browser does not support the audio element.
        </AudioPlayer>
      )}
    </AudioWrapper>
  )
}

export default AudioBlock
