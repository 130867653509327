import styled, { css } from 'styled-components'
import { mq } from 'x'
import { colors } from 'styles'

const test = (props) => `
  color: yellow;
`

const headingStyle = css`
  font-family: 'Oswald', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  text-transform: uppercase;
  font-weight: 700;
`

const headingBar = `
  width: 100%;
  margin: 0 0 25px;
  padding: 3px 12px 6px;
  background-color: ${colors.primaryBlue};
  color: #fff;
  line-height: 100%;
  font-size: 44px;
  text-align: center;
`

const h1Style = (props) => `
  ${headingStyle}
  ${headingBar}

  ${mq.below('lg')} {
    font-size: 36px;
  }
`

const h2Style = (props) => `
  ${headingStyle}
  margin: 25px 0 15px;
  font-size: 18px;
  color: ${colors.textColor};
  font-weight: 700;
`

const h3Style = (props) => `
  ${headingStyle}
  ${headingBar}
  font-size: 24px;
  margin: 0 0 20px;
`

const quoteStyle = (props) => `
  font-size: 28px;
  color: #1e73be;
  text-transform: uppercase;
  font-style: italic;
  line-height: 36px;
`

const paragraphStyle = styled.div`
  p {
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  h1 {
    ${h1Style}
  }
  h2 {
    ${h2Style}
  }
  blockquote {
    ${quoteStyle}
  }
  img {
    max-width: 100%;
    height: auto;
  }
`

export default {
  h1Style: h1Style,
  h2Style: h2Style,
  h3Style: h3Style,
  paragraphStyle: paragraphStyle,
  quoteStyle: quoteStyle,
  headingStyle: headingStyle,
}
