import { React, Link, styled, mq } from 'x'
import { useState, useEffect } from 'hooks'
import { textStyles, colors } from 'styles'
import { innoImg } from 'library'
import Img from 'gatsby-image'

import { Constants } from 'common'
// import ImageBlock from './imageBlock'
const { SEPERATOR_MENU_ITEM } = Constants

const Wrapper = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${colors.textColor};

  ${mq.below('lg')} {
    flex-direction: column;
    margin: 0 0 20px 0;
  }
`

const TopLink = styled(Link)`
  ${textStyles.headingStyle}
  width: 100%;
  padding-top: 20px;
  margin: 0 0 30px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${(props) => props.color ?? colors.textColor};
  font-size: 44px;
  text-decoration: underline;
  cursor: pointer;
  border-top: 2px solid ${colors.textColor};
  ${mq.below('lg')} {
    flex-direction: column;
    font-size: 38px;
    margin: 0 0 40px;
  }
`

const TopLinkThumbnail = styled.figure`
  display: inline-block;
  width: 45%;
  margin: 0 auto 20px;
  ${mq.below('lg')} {
    width: 100%;
    margin: 0 0 20px 0;
  }
`

const TopLinkText = styled.span`
  flex: 2;
`

const List = styled.ul`
  position: relative;
  width: ${(props) =>
    props.cols
      ? `calc((100% - ${props.cols - 1}*13px) / ${props.cols})`
      : 'calc(100% / 3)'};
  margin: 0;
  padding: 0 15px;
  list-style-type: none;

  &:last-of-type {
    &:after {
      content: normal;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -13px;
    width: 3px;
    height: 100%;
    display: block;
    background-color: ${colors.textColor};
    margin: 0 5px;
    ${mq.below('lg')} {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
      height: 2px;
      margin: 5px 0;
    }
  }

  ${mq.below('lg')} {
    width: 100%;
    margin: 3px 0;
  }
`

const SeperatorListItem = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.color ?? colors.textColor};
  border: none;
`

const ListItem = styled.li`
  ${textStyles.headingStyle}
  margin: 0 0 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-decoration: underline;
  cursor: pointer;
`

const ListLink = styled(Link)`
  color: ${(props) => props.color ?? colors.textColor};
`

const ListThumbnail = styled.figure`
  width: 100%;
  margin: 0 0 5px;
  padding: 0;
`

const FixedImage = {
  position: 'relative',
  width: '100%',
  heigth: 'auto',
  objectFit: 'contain',
}

const LinkListBlock = (props) => {
  const { topLink, column1, column2, column3 } = props

  const columns = [column1, column2, column3]
  const [colCount, setColCount] = useState(0)

  useEffect(() => {
    let amountOfColumnsWithItems = 0
    columns.forEach((i) => {
      if (i.length > 0) {
        amountOfColumnsWithItems++
      }
    })

    setColCount(amountOfColumnsWithItems)
  }, [column1, column2, column3])

  return topLink || colCount > 0 ? (
    <Wrapper>
      {topLink.map((item, index) => {
        const { model, url, label, page, newWindow, color, thumbnail } = item

        const { slug, title } = page || {}
        const link = innoImg(url ? url : `/${slug}`)
        const name = label ? label : title

        return (
          <TopLink
            to={link}
            target={newWindow ? '_blank' : '_self'}
            color={color?.rgb}
            rel="nofollow"
            key={`top-${index}`}
          >
            {thumbnail && (
              <TopLinkThumbnail>
                <Img
                  fixed={innoImg(thumbnail?.fixed)}
                  style={{ width: '100%', height: 'auto' }}
                  imgStyle={FixedImage}
                  // fadeIn={false}
                />
              </TopLinkThumbnail>
            )}
            <TopLinkText>{name}</TopLinkText>
          </TopLink>
        )
      })}
      {columns.map(
        (list, i) =>
          list.length > 0 && (
            <List key={i} cols={colCount}>
              {list.map((item, index) => {
                const { model, url, label, page, newWindow, color, thumbnail } =
                  item

                if (model.name === SEPERATOR_MENU_ITEM) {
                  return <SeperatorListItem key={index} color={color?.rgb} />
                }

                const { slug, title } = page || {}
                const link = innoImg(url ? url : `/${slug}`)
                const name = label ? label : title

                // Thumbnail only
                if (!url && !slug && thumbnail) {
                  return (
                    <ListThumbnail>
                      <Img
                        fixed={innoImg(thumbnail?.fixed)}
                        style={{ width: '100%', height: 'auto' }}
                        imgStyle={FixedImage}
                        // fadeIn={false}
                      />
                    </ListThumbnail>
                  )
                }

                return (
                  <ListItem key={index}>
                    <ListLink
                      to={link}
                      target={newWindow ? '_blank' : '_self'}
                      color={color?.rgb}
                      rel="nofollow"
                    >
                      {thumbnail && (
                        <ListThumbnail>
                          <Img
                            fixed={innoImg(thumbnail?.fixed)}
                            style={{ width: '100%', height: 'auto' }}
                            imgStyle={FixedImage}
                            // fadeIn={false}
                          />
                        </ListThumbnail>
                      )}
                      {name}
                    </ListLink>
                  </ListItem>
                )
              })}
            </List>
          )
      )}
    </Wrapper>
  ) : null
}

export default LinkListBlock
