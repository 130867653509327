import { React, styled } from 'x'
import { useEffect, useState } from 'hooks'

const Comments = styled.div`
  width: 100%;
  margin: 20px 0;
`

const Commento = ({ pageId }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && !window.commento) {
      // init empty object so commento.js script extends this with required functionality
      window.commento = {}
      const script = document.createElement('script')
      script.src = `https://commento.jensen.nl/js/commento.js`
      script.defer = true
      script.setAttribute('data-auto-init', false)
      script.setAttribute('data-page-id', `/${pageId}`)
      script.setAttribute('data-id-root', 'commento-box')
      script.onload = () => {
        // load comments
        window.commento.main()
      }
      document.getElementsByTagName('head')[0].appendChild(script)
    } else if (typeof window !== 'undefined' && window.commento) {
      window.commento.reInit({
        pageId: `/${pageId}`,
      })
    }
  }, [])

  return <Comments id="commento-box" />
}

export default Commento
