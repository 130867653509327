import { React, styled } from 'x'
import { textStyles } from 'styles'
import getBlock from '../blocks/getBlock'
import { Constants } from 'common'
import Layout from '../components/layout'

const Wrapper = styled.article`
  width: 100%;
  > img {
    width: 100%;
    height: auto;
  }
`

const Headline = styled.h1`
  ${textStyles.h1Style}
`

const Page = (props) => {
  const {
    path,
    location: { origin, search: urlParams },
    pageContext: { title, content, largeContent, extra },
  } = props

  const isStripeDondateBlock = (node) =>
    node.model && node.model.name === Constants.STRIPE_DONATE_BLOCK

  return (
    <Layout {...props}>
      <Wrapper>
        {(path === undefined || (path !== '/' && path != '/voorbeeld/')) && (
          <Headline>{title}</Headline>
        )}
        {(content || []).map((node, index) => {
          if (isStripeDondateBlock(node))
            return getBlock(node, index, urlParams)
          return getBlock(node, index, extra)
        })}
      </Wrapper>
    </Layout>
  )
}

export default Page
