import { ThemeProvider } from 'styled-components'
import { React, Helmet, styled, mq } from 'x'
import { Constants } from 'common'
import { GlobalStyle, theme } from 'styles'
import getBlock from '../blocks/getBlock'

const Wrapper = styled.div`
  max-width: 1024px;
  margin: 40px auto;
  background-color: #fff;
  color: #000;

  > img {
    width: 100%;
    height: auto;
  }

  ${mq.below('lg')} {
    margin: 20px;
  }

  ${mq.below('md')} {
    img {
      width: 100%;
      height: auto;
    }
  }
`

const StripeDonatePage = (props) => {
  const {
    location: { href, search: urlParams },
    pageContext: { title, content, largeContent, extra, seo },
  } = props

  const seoTags = {
    title: seo && seo.title ? seo.title : title,
    description: seo && seo.description ? seo.description : undefined,
    twitterCard: seo && seo.twitterCard ? seo.twitterCard : undefined,
    image: seo && seo.image && seo.image.url ? seo.image.url : undefined,
  }

  const isStripeDondateBlock = (node) =>
    node.model && node.model.name === Constants.STRIPE_DONATE_BLOCK

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>{seoTags.title}</title>
          <meta property="og:title" content={seoTags.title} />

          <meta
            name="twitter:card"
            content={seoTags.image ? 'summary_large_image' : 'summary'}
          />
          <meta name="twitter:site" content="@robertjensen" />
          <meta name="twitter:creator" content="@robertjensen" />
          <meta property="og:url" content={href} />
          <meta property="og:title" content={title} />
          {seoTags.description && (
            <meta property="og:description" content={seoTags.description} />
          )}
          {seoTags.description && (
            <meta name="description" content={seoTags.description} />
          )}

          {seoTags.image && (
            <meta property="og:image" content={innoImg(seoTags.image)} />
          )}

          {/* <script id="mcjs" async src={mailchimp} /> */}
        </Helmet>
        <GlobalStyle navOpen={0} />
        <Wrapper>
          {(content || []).map((node, index) => {
            if (isStripeDondateBlock(node)) {
              return getBlock(node, index, urlParams)
            }
            return getBlock(node, index, extra)
          })}
        </Wrapper>
      </>
    </ThemeProvider>
  )
}

export default StripeDonatePage
