import { React, Link, styled, mq, rgba } from 'x'
import { breakpoints, colors, textStyles } from 'styles'
import { useEffect, useState, useRef } from 'hooks'
import { jensenShowLogo } from '../../static/img'

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: 0.2s opacity ease-in;
  z-index: 1;
`

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 440px;
  height: auto;
  padding: 20px 0 30px;
  transform: translate(-50%, -50%);
  background-color: ${rgba(colors.primaryBlue, 0.8)};
  text-align: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: 0.2s opacity;
  z-index: 5;

  ${mq.below('sm')} {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transform: none;
  }
`

const Close = styled.button`
  ${textStyles.headingStyle}
  position: absolute;
  top: 6px;
  right: 10px;
  width: auto;
  height: 30px;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: ${colors.white};
  font-size: 30px;
  line-height: 100%;
  cursor: pointer;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.span`
  ${textStyles.headingStyle}
  color: ${colors.white};
  font-size: 30px;
  text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -1px 0 #000,
    1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
`

const Logo = styled.img`
  width: 400px;

  ${mq.below('sm')} {
    width: 280px;
  }
`

const Form = styled.form`
  width: 250px;
  margin: 10px 0;
`

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 3px 5px;
  font-size: 20px;
`

const Submit = styled.button`
  width: 100%;
  margin: 20px 0 0;
  padding: 2px 0 5px;
  border: 0;
  background-color: ${(props) => (props.disabled ? rgba('red', 0.7) : 'red')};
  box-shadow: 0px 0px 4px 2px #000, #000 0px 0px 7px -2px inset;
  ${textStyles.headingStyle}
  color: ${colors.white};
  font-size: 32px;
  line-height: 100%;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const ErrorMsg = styled.span`
  display: block;
  color: red;
  font-size: 16px;
  margin: 5px 0 0;
`

const MailchimpPopup = (props) => {
  const { visible, hidePopup } = props
  const [show, setShow] = useState(false)
  const [unmounting, setUnmounting] = useState(false)

  const [email, setEmail] = useState('')
  const [emailInvalid, setEmailInvalid] = useState(false)

  const formEl = useRef(null)
  const wrapperEl = useRef(null)

  useEffect(() => {
    if (visible && !show && !unmounting) {
      setTimeout(() => {
        setShow(true)
        toggleShow()
      }, 500)
    }
  })

  const toggleShow = (disable = true) => {
    if (typeof window !== 'undefined' && window.document) {
      const html = window.document.getElementsByTagName('html')[0]
      const position = disable ? 'fixed' : 'static'
      const overflow = disable ? 'hidden' : 'visible'

      html.style.position = position
      html.style.overflow = overflow
    }

    setShow(disable)
  }

  const hide = (formSubmitted) => {
    toggleShow(false)
    setUnmounting(true)

    setTimeout(() => {
      hidePopup(formSubmitted)
    }, 500)
  }

  const backdropHide = (e) => {
    if (e.target === wrapperEl.current) {
      hide(false)
    }
  }

  useEffect(() => {
    if (!formEl.current) {
      return
    }

    formEl.current.onsubmit = () => {
      hide(true)
    }
  }, [formEl])

  const onEmailChange = (e) => {
    const {
      target: { value },
    } = e
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isEmailValid = regex.test(String(value).toLocaleLowerCase())

    setEmail(value)

    if (!isEmailValid || value.length <= 5) {
      setEmailInvalid(true)
    } else if (emailInvalid) {
      setEmailInvalid(false)
    }
  }

  return !visible ? null : (
    <Wrapper show={show} onClick={backdropHide} ref={wrapperEl}>
      <Popup show={show}>
        <Close
          onClick={() => {
            hide(false)
          }}
        >
          X
        </Close>
        <Column>
          <Text>
            Ontvang een e-mail
            <br /> bij iedere nieuwe aflevering
          </Text>
          <Logo src={jensenShowLogo} alt="Logo De Jensen Show" />
          <Form
            action="https://jensen.us2.list-manage.com/subscribe/post"
            method="POST"
            target="_blank"
            ref={formEl}
          >
            <input type="hidden" name="u" value="9b8b11cc215b8bcb7490cc382" />
            <input type="hidden" name="id" value="3487d2f5d7" />
            <Input
              value={email}
              name="MERGE0"
              placeholder="E-mailadres"
              onChange={onEmailChange}
            />
            <input type="hidden" value="" name="MERGE1" />
            {emailInvalid && (
              <ErrorMsg>Voer een geldig e-mail adres in</ErrorMsg>
            )}
            <Submit disabled={emailInvalid || email.length == 0}>
              Abonneer!
            </Submit>
          </Form>
          <Text>
            Updates van de Jensen Show
            <br /> en persoonlijke e-mails
          </Text>
        </Column>
      </Popup>
    </Wrapper>
  )
}

export default MailchimpPopup
