import { React, styled } from 'x'
import { textStyles } from 'styles'

const Quote = styled.blockquote`
  ${ textStyles.quoteStyle }
`

const QuoteBlock = props => {
  return (
    <Quote>{ props.quote }</Quote>
  )
}

export default QuoteBlock
