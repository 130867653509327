import { ThemeProvider } from 'styled-components'
import { useState, useLayoutEffect, useEffect } from 'hooks'
import { React, Helmet, styled, mq } from 'x'
import { Constants } from 'common'
import getBlock from '../blocks/getBlock'
import { GlobalStyle, colors, theme, breakpoints } from 'styles'
import Header from './header'
import Footer from './footer'
import Sidebar from './sidebar'
import MailchimpPopup from './mailchimpPopup'
import { innoImg } from 'library'

import Cookies from 'js-cookie'

import ChevronUpIcon from '../assets/svg/chevron_up.svg'

const { SOCIALS_BLOCK } = Constants

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${({ navOpen }) =>
    navOpen &&
    `
    overflow: hidden;
  `}
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${breakpoints.xl}px;
  margin: 0 auto;
  padding: 20px 20px 0 20px;
  background-color: #fff;
  color: #000;

  ${mq.below('xl')} {
    width: ${breakpoints.lg}px;
  }

  ${mq.below('lg')} {
    width: 100%;
  }
`

const FullWidthContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  ${mq.below('lg')} {
    order: 2;
    padding: 0;
  }
`

const Main = styled.main`
  width: ${(props) => (props.sidebar ? '70%' : '100%')};
  padding: 20px;
  ${mq.below('lg')} {
    order: 1;
    width: 100%;
    padding: 0;
  }
`

const ScrollButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: ${colors.secundaryBlue};
  border: none;
  cursor: pointer;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: all 0.3s ease;
  z-index: 100;
`

const scrollToTop = () => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' })
}

const Layout = (props) => {
  const {
    pageContext: {
      menu,
      sidebar,
      settings,
      contentLarge,
      footerMenu,
      title,
      seo,
    },
    location: { href, search: urlParams },
  } = props

  const { newsletterPopup, podcastThumbnail } = settings

  const seoTags = {
    title: seo && seo.title ? seo.title : title,
    description: seo && seo.description ? seo.description : undefined,
    twitterCard: seo && seo.twitterCard ? seo.twitterCard : undefined,
    image: seo && seo.image && seo.image.url ? seo.image.url : undefined,
  }

  const socials =
    sidebar &&
    sidebar.find((block) => block.model && block.model.name === SOCIALS_BLOCK)
  const [navOpen, toggleNavOpen] = useState(false)
  const [scrollBtn, toggleScrollBtn] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const getScrollPosition = () => {
    const body = document.body.getBoundingClientRect()
    const nearBottom = window.innerHeight + window.scrollY > body.height - 400

    toggleScrollBtn(nearBottom)
  }

  useLayoutEffect(() => {
    const windowHeight = window.innerHeight
    const pageHeight = document.body.getBoundingClientRect().height || 0
    const longPage = pageHeight - windowHeight > 800

    if (longPage) {
      window.addEventListener('scroll', getScrollPosition)
      return () => window.removeEventListener('scroll', getScrollPosition)
    }
  })

  useEffect(() => {
    if (newsletterPopup) {
      const hasOldPopupCookie = Cookies.get('mailchimpShown')
      const shownPopup = Cookies.get('mailchimpShown_new')

      if (hasOldPopupCookie) {
        Cookies.remove('mailchimpShown')
      }

      if (!shownPopup) {
        setTimeout(() => {
          setShowPopup(true)
        }, 500)
      }
    }
  }, [])

  const hidePopup = (formSubmitted) => {
    setShowPopup(false)
    Cookies.set('mailchimpShown_new', true, {
      expires: formSubmitted ? 365 : 1,
    })
  }

  // let mailchimp =
  //   'https://chimpstatic.com/mcjs-connected/js/users/9b8b11cc215b8bcb7490cc382/7bf9dc54f1b314684949b6876.js'
  // if (process.env.GATSBY_PRODUCTION) {
  //   mailchimp =
  //     'https://chimpstatic.com/mcjs-connected/js/users/9b8b11cc215b8bcb7490cc382/d6f86b3aac888aabb95d217d2.js'
  // }

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>{seoTags.title}</title>
          <meta property="og:title" content={seoTags.title} />

          <meta
            name="twitter:card"
            content={seoTags.image ? 'summary_large_image' : 'summary'}
          />
          <meta name="twitter:site" content="@robertjensen" />
          <meta name="twitter:creator" content="@robertjensen" />
          <meta property="og:url" content={href} />
          <meta property="og:title" content={title} />
          {seoTags.description && (
            <meta property="og:description" content={seoTags.description} />
          )}
          {seoTags.description && (
            <meta name="description" content={seoTags.description} />
          )}

          {seoTags.image && (
            <meta property="og:image" content={innoImg(seoTags.image)} />
          )}

          {/* <script id="mcjs" async src={mailchimp} /> */}
        </Helmet>
        <GlobalStyle navOpen={navOpen ? 1 : 0} />
        <Root>
          <ScrollButton show={scrollBtn ? 1 : 0} onClick={scrollToTop}>
            <ChevronUpIcon />
          </ScrollButton>
          <Header
            menu={menu}
            socials={socials}
            navOpen={navOpen}
            toggleNavOpen={toggleNavOpen}
          />
          <Container>
            <Main sidebar={sidebar ? 1 : 0}>{props.children}</Main>
            <Sidebar sidebar={sidebar} podcastThumbnail={podcastThumbnail} />
            {contentLarge && (
              <FullWidthContent>
                {contentLarge.map((node, i) => getBlock(node, i, urlParams))}
              </FullWidthContent>
            )}
            <Footer menu={footerMenu} socials={socials} />
          </Container>
          {showPopup && (
            <MailchimpPopup visible={showPopup} hidePopup={hidePopup} />
          )}
        </Root>
      </>
    </ThemeProvider>
  )
}

export default Layout
