import { React, Link, styled, mq, Image } from 'x'
import { colors } from 'styles'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  &:nth-of-type(odd) {
    > figure {
      order: 2;
    }
    > div {
      order: 1;
    }
  }
  ${ mq.below('lg') } {
    flex-direction: column;
    &:nth-of-type(odd) {
      > figure {
        order: 1;
      }
      > div {
        order: 2;
      }
    }
  }
`

const LogoFigure = styled.figure`
  width: 30%;
  margin: 0;
  padding: 10px 0;
  display: inline-flex;
  order: 1;
  ${ mq.below('lg') } {
    width: 100%;
    padding: 20px 0;
  }
`

const Information = styled.div`
  width: 60%;
  display: inline-flex;
  flex-direction: column;
  order: 2;
  ${ mq.below('lg') } {
    width: 100%;
  }
`

const Name = styled.span`
  margin: 10px 0 7px;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
`

const Description = styled.div`
  font-size: 14px;
  line-height: 26px;
  color: #666;
`

const Button = styled(Link)`
  width: auto;
  padding: 8px 15px 8px;
  margin-top: 25px;
  align-self: flex-start;
  background-color: ${ '#1e73be' /*colors.secundaryBlue*/ };
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  color: ${ colors.white };
  transition: background-color .3s ease;
  &:hover {
    background-color: #222;
  }
`

const Sponsor = props => {
  const {
    sponsor: {
      name,
      description,
      logo,
      url,
    },
  } = props

  return (
    <Wrapper>
      { logo?.fixed && (
        <LogoFigure>
          <Image
            fadeIn={false}
            style={{ 'alignSelf' : 'center'}}
            fixed={ logo.fixed }/>
        </LogoFigure>
      ) }
      <Information>
        <Name>{ name }</Name>
        <Description dangerouslySetInnerHTML={ { __html: description } }/>
        <Button to={ url } target="_blank">Klik hier</Button>
      </Information>
    </Wrapper>
  )
}

export default Sponsor
