import { React, styled } from 'x'
import { Sponsor } from 'components'
import { colors } from 'styles'

const Wrapper = styled.div`
  margin: 30px 0 0;
  display: flex;
  flex-direction: column; 
`

const border = () => `
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: ${ colors.textColor };
`

const Title = styled.h3`
  position: relative;
  color: ${ colors.textColor };
  font-size: 21px;
  text-align: center;
  line-height: 40px;
  &:before {
    ${ border }
  }
  &:after {
    ${ border }
    bottom: -5px;
  }
`

const SponsorBlock = props => {
  const { 
    title,
    sponsors
  } = props
  
  return (
    <Wrapper>
      <Title>{ title }</Title>
      {
        sponsors.map((sponsor, index) => do {
          <Sponsor key={ index } { ...sponsor } />
        })
      }
    </Wrapper>
  )
}

export default SponsorBlock
