import { React } from 'x'
import { Constants } from 'common'
import {
  QuoteBlock,
  TextBlock,
  VideoBlock,
  AudioBlock,
  AdBlock,
  ImageBlock,
  AudioEmbedBlock,
  VideoEmbedBlock,
  SocialsBlock,
  DonateBlock,
  ArticleOverviewBlock,
  FeaturedArticleBlock,
  LatestPodcastsBlock,
  SponsorBlock,
  PetjeAf,
  LinkListBlock,
  PlayerBlock,
  StripeDonateBlock,
} from 'blocks'

const {
  TEXT_BLOCK,
  QUOTE_BLOCK,
  IMAGE_BLOCK,
  VIDEO_BLOCK,
  AUDIO_BLOCK,
  AD_BLOCK,
  AUDIO_EMBED_BLOCK,
  SOCIALS_BLOCK,
  DONATE_BLOCK,
  ARTICLE_OVERVIEW_BLOCK,
  FEATURED_ARTICLE_BLOCK,
  SPONSOR_BLOCK,
  STRIPE_DONATE_BLOCK,
  PETJE_AF_BLOCK,
  VIDEO_EMBED_BLOCK,
  LINK_LIST_BLOCK,
  PLAYER_BLOCK,
  LATEST_BLOCK,
  LATEST_PODCASTS_BLOCK,
} = Constants

const getBlock = (node, index = 0, extra = null) => {
  if (node.model == undefined) {
    return null
  }

  if (node.model.name === TEXT_BLOCK) {
    return <TextBlock key={index} text={node.text} />
  } else if (node.model.name === QUOTE_BLOCK) {
    return <QuoteBlock key={index} quote={node.quote} />
  } else if (node.model.name === IMAGE_BLOCK) {
    return <ImageBlock key={index} {...node.image} />
  } else if (node.model.name === VIDEO_BLOCK) {
    return <VideoBlock key={index} {...node.video} />
  } else if (node.model.name === PLAYER_BLOCK) {
    return <PlayerBlock key={index} videoId={node.videoId} />
  } else if (node.model.name === AUDIO_BLOCK) {
    return (
      <AudioBlock
        key={index}
        upload={node.audio}
        anchor={node.anchorDownload}
      />
    )
  } else if (node.model.name === AD_BLOCK) {
    return <AdBlock key={index} html={node.googleAd} />
  } else if (node.model.name === AUDIO_EMBED_BLOCK) {
    return (
      <AudioEmbedBlock
        key={index}
        embed={node.embed}
        selfHostedAudioId={node.selfHostedAudioId}
        inArticle={true}
      />
    )
  } else if (node.model.name === SOCIALS_BLOCK) {
    return <SocialsBlock key={index} socials={node} />
  } else if (node.model.name === DONATE_BLOCK) {
    return <DonateBlock key={index} {...node} />
  } else if (node.model.name === ARTICLE_OVERVIEW_BLOCK) {
    return <ArticleOverviewBlock key={index} isBlock={true} {...node} />
  } else if (node.model.name === FEATURED_ARTICLE_BLOCK) {
    return <FeaturedArticleBlock key={index} {...node} />
  } else if (node.model.name === LATEST_BLOCK) {
    return (
      <ArticleOverviewBlock key={index} isBlock small limitMobile {...node} />
    )
  } else if (node.model.name === LATEST_PODCASTS_BLOCK) {
    return <LatestPodcastsBlock key={index} {...node} thumb={extra} />
  } else if (node.model.name === SPONSOR_BLOCK) {
    return (
      <SponsorBlock
        key={index}
        title={node.title}
        sponsors={extra?.sponsors || []}
      />
    )
  } else if (node.model.name === PETJE_AF_BLOCK) {
    return <PetjeAf key={index} title={node.title} pageName={node.pageName} />
  } else if (node.model.name === VIDEO_EMBED_BLOCK) {
    return <VideoEmbedBlock key={index} embed={node.embed} />
  } else if (node.model.name === LINK_LIST_BLOCK) {
    return <LinkListBlock key={index} {...node} />
  } else if (node.model.name === STRIPE_DONATE_BLOCK) {
    return <StripeDonateBlock key={index} search={extra} {...node} />
  } else {
    return null
  }
}

export default getBlock
