import { React, Link, styled } from 'x'
import { useState, useEffect } from 'hooks'
import { colors, textStyles } from 'styles'
import { loadStripe } from '@stripe/stripe-js'
import { logo } from '../../static/img'

import Cookies from 'js-cookie'

const stripePromise = loadStripe(
  'pk_live_51IlaitL13kRZrJ2SDLkL4F5TRPwcIxmsEV2o7faQq0qZfJTCXyZn6relClb9qY3lmEvodtNKGGmfQu2Al4q92ACU00CKrq3cUB'
)

const Donate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.borderColor};
`

const Heading = styled.h3`
  ${textStyles.h3Style}
  margin-bottom: 20px;

  + figure {
    margin-top: -20px;

    img {
      margin: 20px 0;
    }
  }
`

const Label = styled.span`
  display: inline-block;
  font-weight: bold;
  text-align: center;
  width: 100%;
`

const PaymentWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid ${colors.borderColor};
  span {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 100%;
  }
`

const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin: 15px 0 0;
`

const PaymentInput = styled.input`
  width: 70%;
  padding: 5px 10px;
  border: 1px solid ${colors.borderColor};
  border-radius: 3px;
  line-height: 20px;
  color: #777;
`

const PaymentSubmit = styled.button`
  margin: 0 0 0 10px;
  border: 0;
  border-radius: 2px;
  background-color: ${colors.secundaryBlue};
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  &[disabled] {
    opacity: 0.5;
  }
`

const Figure = styled.figure`
  height: auto;
  padding: 0;
  margin: 0 auto;

  img {
    margin: 15px 0;
  }
`

const ErrorLabel = styled.span`
  margin: 20px 0 0;
  color: rgb(255, 0, 0);
`

const StripeDonateBlock = (props) => {
  const { title, search } = props
  const params = search ? new URLSearchParams(search) : null
  const value = params ? params.get('amount') : 10

  const [paymentValue, setPaymentValue] = useState(value)
  const [isDonating, setDonating] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (params && value) {
      submitPayment()
    }
  }, [])

  const startPayment = async (val) => {
    await setPaymentValue(val)
    await submitPayment()
  }

  const submitPayment = async (e) => {
    e?.preventDefault()

    if (isDonating) {
      return
    }

    const host =
      window.location.hostname == 'localhost' ? 'http://localhost:3000' : ''
    const val = paymentValue

    if (isNaN(parseFloat(val))) {
      return setError('Geen geldig bedrag')
    }
    setDonating(true)
    try {
      const stripe = await stripePromise
      const result = await fetch(
        `${host}/api/stripe/create_payment?amount=${val}`
      )
      if (result.status !== 200) {
        throw 'Something went wrong creating a Stripe payment:' + result.status
      } else {
        const { session } = await result.json()
        Cookies.set('paymentId', session, { expires: 7 })
        await stripe.redirectToCheckout({
          sessionId: session,
        })
      }
    } catch (err) {
      console.error(err)
      setError('Er is iets fout gegaan, probeer het opnieuw')
    }

    setDonating(false)
  }

  return (
    <Donate>
      {title && <Heading>{title}</Heading>}
      <Figure>
        <img src={logo} alt="Logo Jensen.nl" />
      </Figure>
      <PaymentWrapper onSubmit={submitPayment}>
        <Label>Vul je bedrag in en doneer</Label>
        <InputWrapper>
          <PaymentInput
            type="number"
            value={paymentValue}
            onChange={(e) => {
              setPaymentValue(e.target.value)
            }}
          />
          <PaymentSubmit disabled={isDonating}>Doneer</PaymentSubmit>
        </InputWrapper>
        {error.length > 0 && <ErrorLabel>{error}</ErrorLabel>}
      </PaymentWrapper>
    </Donate>
  )
}

export default StripeDonateBlock
