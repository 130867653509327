import { React, styled } from 'x'

const Ratio = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${ props => props.ratioHeight / props.ratioWidth * 100 }%;
  
  > * {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: center center;
  }
`

const RatioImage = props => (
  <Ratio { ...props }>
    { props.children }
  </Ratio>
)

export default RatioImage
