import { React, styled, mq } from 'x'
import { useEffect } from 'hooks'

const DefaultWrapper = styled.div`
  width: 100%;
  display: block;
  margin: 20px auto;
  > div {
    position: relative;
    padding-bottom: 68%;
    height: 0 !important;
    width: 100% !important;

    ${mq.above('md')} {
      padding-bottom: 61%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const VideoEmbedBlock = (props) => {
  const { embed } = props

  const hasKalturaScript = new RegExp(
    /^(<script src="https:\/\/kaltura.jensen.nl).*(><\/script>)/
  )
  // const hasKalturaPlayer = new RegExp(/(<script>).*(<\/script>)$/)
  // const kalturaPlayer = embed.match(hasKalturaPlayer)

  let embedBody = embed
  if (hasKalturaScript.test(embedBody)) {
    embedBody = `<div><div style="position:absolute;left:0;right:0;top:0;bottom:0;display:flex;justify-content:center;align-items:center;background: rgba(0,0,0,0.2);">Deze video is niet meer beschikbaar</div></div>`
    // Remove Kaltura script
    // embedBody = embedBody.replace(embedBody.match(hasKalturaScript)[0], '')
  }

  // if (hasKalturaPlayer.test(embedBody)) {
  //   // Remove Kaltura function execution
  //   embedBody = embedBody.replace(kalturaPlayer[0], '')
  // }

  // useEffect(() => {
  //   const entryId = kalturaPlayer[0].match(/"entry_id": "([^"]+)"/)[1]
  //   const customFlashVars = `"flashvars": {"hlsjs": {"p2pTrackers": "wss://wt-tracker.jensen.nl", "p2pSwarmId":"${entryId}"}, "statistics": {"plugin": false}}`
  //   const kaltura = kalturaPlayer[0]
  //     .replace(kalturaPlayer[1], '')
  //     .replace(kalturaPlayer[2], '')
  //     .replace('"flashvars": {}', customFlashVars)
  //     .replace(/"cache_st": [^,]+,/, '')

  //   const loadKaltura = () => {
  //     if (typeof window !== 'undefined' && typeof window.mw !== 'undefined') {
  //       mw.setConfig('forceMobileHTML5', true)
  //     }
  //     eval(kaltura)
  //   }

  //   const destroyKaltura = () => {
  //     const playerId = kaltura.match(new RegExp(/("targetId":)(.*?)(,)/))[2]
  //     kWidget.destroy(playerId)
  //   }

  //   if (!window.kWidget) {
  //     window.loadKaltura = loadKaltura
  //   } else {
  //     loadKaltura()
  //   }

  //   return () => {
  //     if (window.kWidget) {
  //       destroyKaltura()
  //     }
  //   }
  // }, [])

  return <DefaultWrapper dangerouslySetInnerHTML={{ __html: embedBody }} />
}

export default VideoEmbedBlock
