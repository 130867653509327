import { React } from 'x'
import { EmbedWrapper } from 'styles'
import { PlayerBlock } from 'blocks'

const getEmbed = (provider, videoId) => {
  if (provider === 'vimeo') {
    return `<iframe src="https://player.vimeo.com/video/${videoId}" width="640" height="360" frameborder="0" title="vimeo-player" allowfullscreen></iframe>`
  }
  return `<iframe src="https://www.youtube.com/embed/${videoId}" width="560" height="315" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
}

const VideoBlock = (props) => {
  const { video, selfHostedVideoId, previewImage } = props

  if (selfHostedVideoId) {
    return (
      <PlayerBlock videoId={selfHostedVideoId} previewImage={previewImage} />
    )
  }

  return (
    <EmbedWrapper
      dangerouslySetInnerHTML={{
        __html: video && getEmbed(video.provider, video.providerUid),
      }}
    />
  )
}

export default VideoBlock
