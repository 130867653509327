import { React, styled } from 'x'
import { textStyles } from 'styles'
import { petjeAf as petjeAfLogo } from '../../static/img'

const Wrapper = styled.div`
  margin: 20px 0;
`

const BlockTitle = styled.h4`
  ${ textStyles.h3Style }
`

const DonateWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 4px;
  font-size: 16px;
  p {
    font-weight: 400;
    color: #6c7686;
    font-size: 14px;
    line-height: 1.7;
    margin-top: 12px;
  }
`

const Box = styled.div`
  padding: 22px;
  border-top: ${ props => props.border ? '1px solid rgba(0, 0, 0, .125)' : 'none' };
`

const ImageWrap = styled.a`
  display: block;
  margin-bottom: 12px;
  img {
    width: 100px;
    height: auto;
  }
`

const Title = styled.h5`
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
`

const Amount = styled.span`
  font-size: 20px;
  font-weight: 700;
  span {
    color: #6c7686;
    font-size: 16px;
  }
`

const Button = styled.a`
  padding: 8px 16px;
  border-radius: 4px;
  display: inline-block;
  font-weight: 700;
  color: ${props => props.info ? '#24b7fa' : '#ffffff'} ;
  background: ${props => props.info ? 'transparent' : '#4cd964'};
  border: 1px solid ${props => props.info ? '#24b7fa' : '#4cd964'};
  text-decoration: none;
  font-family: 'Nunito', sans-serif;
  transition: all .3s ease;
  &:hover, &:focus {
    color: ${props => props.info ? '#fff' : '#ffffff'} ;
    background: ${props => props.info ? '#24b7fa' : '#2dd249'};
    border: 1px solid ${props => props.info ? '#24b7fa' : '#2ac845'};
  }
`

const PetjeAf = ({ title, pageName }) => {
  const petjeAf = pageName ? pageName : 'jensen' 
  
  return (
    <Wrapper>
      <BlockTitle>{ title }</BlockTitle>
      <DonateWrapper>
        <Box>
          <ImageWrap href={`https://petje.af/${ petjeAf }/`} target="_blank">
            <img src={ petjeAfLogo } alt="Petje af logo" />
          </ImageWrap>
          <Title>Eenmalig doneren</Title>
          <p>Neem eenmalig je petje af! Kies zelf het bedrag.</p>
          <Button info={ 1 } href={`https://petje.af/${ petjeAf }/`} target="_blank">Eenmalig petje af!</Button>
        </Box>

        <Box border={ 1 }>
          <Title>Per maand vanaf</Title>
          <Amount>€ 5,- <span>per maand</span></Amount>
          <p>Word lid en steun ons al vanaf € 5,- per maand</p>
          <Button href={`https://petje.af/checkout/${ petjeAf }/`} target="_blank">Neem je petje af!</Button>
        </Box>
      </DonateWrapper>
    </Wrapper>
  )
}

export default PetjeAf
