import { React, styled } from 'x'
import { Link } from 'common'
import { colors } from 'styles'
import { useState, useEffect } from 'hooks'

import FacebookIcon from '../assets/svg/facebook_brands_icon.svg'
import TwitterIcon from '../assets/svg/twitter_brands_icon.svg'
import WhatsappIcon from '../assets/svg/whatsapp_brands_icon.svg'
import GeneralShareIcon from '../assets/svg/share_general_icon.svg'

const SocialWrapper = styled.div`
  display: flex;
  padding: 20px 0;
  margin-bottom: 20px;
  border-top: 1px solid ${ colors.borderColor };
  border-bottom: 1px solid ${ colors.borderColor };
`

const BtnStyling = () => `
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 2px;
  line-height: 40px;
`

const SocialLink = styled(Link)`
  ${BtnStyling}
`

const NativeShareBtn = styled.button`
  border: 0;
  background-color: ${colors.primaryBlue};
  ${BtnStyling}
`

const SocialSharer = props => {
  const { 
    url,
    title
  } = props
  
  const socials = [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u=%url%',
      icon: <FacebookIcon />,
      color: '#516eab',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/intent/tweet?text=%title%&url=%url%&via=Jensen',
      icon: <TwitterIcon />,
      color: '#29c5f6',
    },
    {
      name: 'WhatsApp', 
      url: 'whatsapp://send?text=%title%&nbsp;%url%',
      icon: <WhatsappIcon />,
      color: '#43d854',
    }
  ]

  const [nativeShareBtn, setNativeShareBtn] = useState(null)
  useEffect(() => {
    if (navigator && navigator.share) {
      const button = {
        name: 'Native',
        action: async () => {
          try {
            const shareData = {
              title: 'Jensen.nl',
              text: title,
              url: url,
            }
            await navigator.share(shareData)
          } catch(err) {
            console.error('Error whith nav share', err)
          }
        }
      }
      setNativeShareBtn(button)
    }
  },[])

  return (
    <SocialWrapper>
      {nativeShareBtn ? (
        <NativeShareBtn onClick={nativeShareBtn.action}>
          <GeneralShareIcon />
        </NativeShareBtn>
      ) : (
        socials.map((social, index) => {
          const link = social.url.replace('%title%', encodeURIComponent(title)).replace('%url%', encodeURIComponent(url))
          return (
            <SocialLink to={link} key={index} style={{ backgroundColor: social.color}}>
              {social.icon}
            </SocialLink>
          )
        })
      )}
    </SocialWrapper>
  )
}

export default SocialSharer
