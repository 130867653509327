import { React, Image, styled } from 'x'
import { innoImg } from 'library'

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin: 10px auto;
`

const ImageBlock = (props) => {
  const { fluid, alt } = props

  return <StyledImage fadeIn={false} fluid={innoImg(fluid)} alt={alt} />
}

export default ImageBlock
