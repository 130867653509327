import { React, Image, Link, styled } from 'x'
import { useState, useEffect } from 'hooks'
import { colors, textStyles } from 'styles'
import { innoImg, formattedDate, getAudioUrl } from 'library'
import { default as getBlock } from './getBlock'
import { Constants } from 'common'
import { FountainIcon, PodverseIcon } from '../../static/img'
import Img from 'gatsby-image'

import DownloadIcon from '../assets/svg/download_icon.svg'

const { AUDIO_EMBED_BLOCK } = Constants
const darkGrey = '#6c7686'

const Wrapper = styled.div`
  margin: 20px 0;
`

const Content = styled.div`
  margin: 20px 0 0;
  padding: 20px;
  border: 1px solid ${colors.borderColor};
  border-radius: 4px;
`

const BlockTitle = styled.h4`
  ${textStyles.h3Style}
  margin: 0;
`

const DateLabel = styled.span`
  font-family: 'Oswald', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: ${darkGrey};
`

const FeaturedEpisode = (() => {
  const Wrapper = styled.div`
    margin: 0 0 20px;
    border-bottom: 1px solid ${colors.borderColor};

    a {
      text-decoration: none;
      color: inherit;
    }
  `

  const StyledLink = styled(Link)`
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `

  const Cover = styled.figure`
    width: 100px;
    height: 100px;
    margin: 0;
    padding: 0;

    .styledImg {
      display: block;
      width: 100%;
      height: 100%;
    }
  `

  const Meta = styled.div`
    width: calc(100% - 120px);
    margin-left: 20px;
    display: inline-flex;
    flex-direction: column;
  `

  const Title = styled.span`
    ${textStyles.h2Style}
    margin: 0 0 5px;
  `

  const Media = styled.div`
    display: flex;
    margin: 0;
  `

  const Download = styled.a`
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    margin: 0 0 20px;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
  `

  const Icon = styled.button`
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    padding: 8px;
    margin-right: 10px;
    border: 0;
    background-color: ${colors.secundaryBlue};
    border-radius: 4px;
    cursor: inherit;

    svg {
      width: 100%;
    }
  `

  const Text = styled.span`
    font-family: 'Oswald', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
    font-size: 16px;
    text-transform: uppercase;
  `

  return ({ title, slug, cover, meta: { firstPublishedAt }, content }) => {
    const { fluid } = cover
    const parsedDate = new Date(firstPublishedAt)
    const date = formattedDate(parsedDate)
    const [audio, setAudio] = useState(null)
    const [file, setFile] = useState(null)

    useEffect(() => {
      content.forEach((v) => {
        const {
          model: { name },
        } = v
        if (name && name === AUDIO_EMBED_BLOCK) {
          setAudio(getBlock(v))

          const { selfHostedAudioId } = v
          if (selfHostedAudioId) {
            setFile(getAudioUrl(selfHostedAudioId))
          }
        }
      })
    }, [content])

    return (
      <Wrapper>
        <StyledLink to={slug} asHref>
          <Cover>
            <Img fadeIn={false} fluid={innoImg(fluid)} className="styledImg" />
          </Cover>
          <Meta>
            <Title>{title}</Title>
            <DateLabel>
              <time dateTime={firstPublishedAt}>{date}</time>
            </DateLabel>
          </Meta>
        </StyledLink>
        {audio && (
          <>
            <Media>{audio}</Media>
            {file && (
              <Download href={file.url} download={'djs.mp3'}>
                <Icon>
                  <DownloadIcon />
                </Icon>
                <Text>Download aflevering (mp3)</Text>
              </Download>
            )}
          </>
        )}
      </Wrapper>
    )
  }
})()

const SubHeader = (() => {
  const Wrapper = styled.div`
    width: 100%;
    margin: 20px 0 10px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  `

  const Title = styled.span`
    ${textStyles.h2Style}
    margin: 0;
    font-size: 20px;
  `

  const SubTitle = styled.span`
    margin: 10px 0 20px;
    font-family: 'Oswald', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
    color: #6f6f6f;
    text-transform: uppercase;
    font-size: 12px;
  `

  const Icons = styled.div`
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
  `

  const Social = styled.a`
    width: 32px;
    height: 32px;

    + a {
      margin-left: 20px;
    }
  `

  const Image = styled.img`
    width: 100%;
  `

  return () => {
    const fountain = 'https://fountain.fm/show/SKjcjbFQ219DCs7t7VHC'
    const podverse = 'https://podverse.fm/podcast/hEW5zE6Gf1'

    return (
      <Wrapper>
        <Title>Abonneer</Title>
        <SubTitle>App Store + Google Play + Web</SubTitle>
        <Icons>
          <Social href={fountain} target="_blank">
            <Image src={FountainIcon} />
          </Social>
          <Social href={podverse} target="_blank">
            <Image src={PodverseIcon} />
          </Social>
        </Icons>
      </Wrapper>
    )
  }
})()

const SingleEpisode = (() => {
  const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin: 10px 0;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }
  `

  const Title = styled.span`
    ${textStyles.h2Style}
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  `

  const Meta = styled(DateLabel)`
    margin: 5px 0 0;
  `

  return (episode) => {
    const {
      title,
      setFeatured,
      meta: { firstPublishedAt },
    } = episode
    const parsedDate = new Date(firstPublishedAt)
    const date = formattedDate(parsedDate)

    return (
      <Wrapper onClick={() => setFeatured(episode)}>
        <Title>{title}</Title>
        <Meta>{date}</Meta>
      </Wrapper>
    )
  }
})()

const LatestPodcastsBlock = (props) => {
  const { title, podcasts, thumb } = props
  if (podcasts.length === 0) return null
  const [featured, setFeatured] = useState(podcasts[0] || {})

  return (
    <Wrapper>
      {title && <BlockTitle>{title}</BlockTitle>}
      <SubHeader />
      <Content>
        {featured && <FeaturedEpisode cover={thumb} {...featured} />}
        {podcasts &&
          podcasts.map((e, i) => (
            <SingleEpisode key={i} setFeatured={setFeatured} {...e} />
          ))}
      </Content>
    </Wrapper>
  )
}

export default LatestPodcastsBlock
